import { Counter } from '@c/counter';
import { TrashIcon } from '@heroicons/react/20/solid';
import { formatCurrency, getCartItemFromProductDocument } from '@util/index';
import { CartItem } from '@util/types/firestore/carts';
import { getProductSlug } from '@util/urlHelpers';
import { useShoppingCart } from 'context/ShoppingCartContext';
import { ProductDocument } from 'models/product';
import Link from 'next/link';
import SafeImage from './SafeImage';

export const CartItemDisplay = ({
  product,
  cartItem,
}: {
  product: ProductDocument;
  cartItem?: CartItem;
}) => {
  const { increaseCartQty, decreaseCartQty, removeFromCart } =
    useShoppingCart();

  return (
    <div className="flex gap-[1.6rem]">
      <Link href={getProductSlug(product)} className="h-[10rem] w-[10rem]">
        <SafeImage
          alt={product.title}
          src={product.thumbnail}
          className="aspect-square h-[7rem] w-[7rem] rounded-2xl"
        />
      </Link>
      <div className="flex w-full flex-col gap-[0.4rem] overflow-hidden">
        <Link href={getProductSlug(product)}>
          <p className="w-full overflow-hidden text-ellipsis whitespace-nowrap text-[1.5rem] font-normal">
            {product.title}
          </p>
          <p className="text-[1.3rem] font-medium text-brand-gray">
            {product.category}
          </p>
          {cartItem?.size?.letter && (
            <p className="text-[1.3rem] font-medium text-brand-gray">
              Size: {cartItem.size.letter}
            </p>
          )}
          {cartItem?.size?.number && (
            <p className="text-[1.3rem] font-medium text-brand-gray">
              Size: {cartItem.size.number}
            </p>
          )}
          {cartItem?.color && (
            <p className="text-[1.3rem] font-medium text-brand-gray">
              Color: {cartItem.color}
            </p>
          )}
        </Link>
        <div className="flex flex-col justify-end gap-[1.6rem] lg:flex-row lg:items-center">
          <p className="mr-auto font-semibold">
            {formatCurrency(cartItem?.product_cost ?? 0)}
          </p>
          <div className="flex gap-[1.6rem]">
            <Counter
              val={cartItem?.qty ?? 0}
              increment={
                () =>
                  increaseCartQty(
                    getCartItemFromProductDocument(product, null),
                    false
                  ) // why do we need to get the cart item again ?
              }
              decrement={() => decreaseCartQty(product.id)}
            />
            <button
              className="hidden lg:block"
              onClick={() => removeFromCart(product.id)}
            >
              <TrashIcon />
            </button>
          </div>
        </div>
      </div>
      <button className="lg:hidden" onClick={() => removeFromCart(product.id)}>
        <TrashIcon />
      </button>
    </div>
  );
};
