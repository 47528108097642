import { getItems } from '@util/analytics';
import { getDiscount } from '@util/firestore/cart';
import { getPromos } from '@util/firestore/stripe';
import { Cart } from '@util/types/firestore/carts';
import React from 'react';

export async function handleApplyDiscount(
  discountCode: string,
  setIsApplyingDiscount: React.Dispatch<React.SetStateAction<boolean>>,
  cart: Cart,
  addCoupons: (coupons: any) => void,
  realTimeProducts: any,
  logEvent: (...args: any[]) => void,
  setDiscountCode: React.Dispatch<React.SetStateAction<string>>,
  showErrorToast: (text: string) => void,
  discountInputRef: React.RefObject<HTMLInputElement>
) {
  if (!discountCode) return;
  setIsApplyingDiscount(true);
  try {
    const data = await getPromos({
      customer: '',
      code: discountCode,
      total: cart?.total ?? 0,
      subtotal: cart?.subtotal ?? 0,
      account_ids: Array.from(
        new Set(cart?.items.map((item) => item.account_id) ?? [])
      ),
    });
    if (data[0]) {
      const code = data[0].coupon.name;
      const coupons = data.map((promo) => ({ ...promo.coupon, code }));
      addCoupons(coupons);
      logEvent('select_promotion', {
        items: getItems(realTimeProducts),
        promotion_id: code,
        promotion_name: data[0].coupon.name,
        location_id: 'checkout',
      });
    } else {
      setDiscountCode('');
      showErrorToast('Invalid discount code');
      discountInputRef.current?.focus();
    }
  } catch (e) {
    showErrorToast((e as Error).message);
    setDiscountCode('');
    discountInputRef.current?.focus();
  } finally {
    setIsApplyingDiscount(false);
  }
}

export const getTotalDiscount = (cart: Cart) => {
  const total =
    cart?.items.reduce((acc, item) => {
      return acc + item.product_cost * item.qty;
    }, 0) ?? 0;

  return getDiscount(total, cart?.coupons, cart?.items ?? []);
};
