import { MinusIcon, PlusIcon } from "./icons";

export const Counter = ({
    val,
    increment,
    decrement,
  }: {
    val: number;
    increment: () => void;
    decrement: () => void;
  }) => {
    return (
      <div className="flex w-[9.6rem] items-center gap-x-2 justify-between rounded-full border-[1px] border-brand-lighter-gray px-[1.3rem] text-center">
        <button className="h-[3rem] w-[2rem]" onClick={decrement}>
          <MinusIcon />
        </button>
        <span className="pl-1">{val}</span>
        <button className="h-[3rem] w-[2rem]" onClick={increment}>
          <PlusIcon />
        </button>
      </div>
    );
  };
