import { useState } from 'react';
import { ProductDocument } from 'models/product';
import Image from 'next/image';
import { MinusIcon, PlusIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { useShoppingCart } from 'context/ShoppingCartContext';
import { formatCurrency } from '@util/index';
import { useQuery } from '@tanstack/react-query';
import { getUserById } from '@util/firestore/users';
import { ShieldCheckIcon } from './icons';
import Link from 'next/link';
import clsx from 'clsx';
import { logError } from '@util/logError';
import { getProductSlug } from '@util/urlHelpers';

interface CardProductProps {
  product: ProductDocument;
  onRemove: () => void;
  showSellerInfo: boolean;
  showBottomBorder: boolean;
}

export default function CartProduct({
  product,
  onRemove,
  showSellerInfo,
  showBottomBorder,
}: CardProductProps) {
  const { cart, increaseCartQty, decreaseCartQty, setCartOpen } =
    useShoppingCart();

  const getCartItem = (productId: string) => {
    return cart?.items.find((i) => i.product_id === productId);
  };

  const cartItem = getCartItem(product.id);

  const { data: seller, isLoading: isSellerLoading } = useQuery({
    queryKey: ['user', product.seller_id],
    queryFn: () => getUserById(product.seller_id),
    enabled: product.seller_id !== '',
  });

  if (!product || !cartItem) return <></>;

  const handleIncrease = () => {
    const canIncrease = cartItem !== undefined;
    if (canIncrease) {
      try {
        increaseCartQty(cartItem, false);
      } catch (error) {
        logError(error);
      }
    }
  };

  const handleDecrease = () => {
    try {
      decreaseCartQty(product.id);
    } catch (error) {
      logError(error);
    }
  };

  return (
    <div
      className={clsx(
        'flex flex-col gap-y-4',
        showBottomBorder && 'border-b border-zinc-200 pb-8',
        !showSellerInfo && 'pt-12'
      )}
    >
      {showSellerInfo && (
        <div className="mb-8 flex w-full items-center justify-between">
          <Link
            href={`/profile/${seller?.username}`}
            onClick={() => setCartOpen(false)}
            className="flex items-center gap-x-4"
          >
            {seller?.name === 'MXLocker' ? (
              <div className="flex h-16 w-16 items-center justify-center rounded-full bg-zinc-100 p-2">
                <MxIcon />
              </div>
            ) : (
              <div className="h-16 w-16 rounded-full">
                <Image
                  src={seller?.photo ?? ''}
                  alt={seller?.username ?? ''}
                  className="aspect-square h-full w-full rounded-full object-cover"
                  height={200}
                  width={200}
                />
              </div>
            )}

            <span className="text-[1.6rem] font-medium text-black">
              {isSellerLoading
                ? 'Loading...'
                : seller
                ? seller.username
                : 'MXLocker'}
            </span>

            {seller?.is_verified && <ShieldCheckIcon />}
          </Link>

          <XMarkIcon className="h-10 w-10 text-zinc-800" onClick={onRemove} />
        </div>
      )}

      <div className="flex gap-x-4">
        <Link href={getProductSlug(product)} onClick={() => setCartOpen(false)}>
          <Image
            src={product.thumbnail}
            alt={product.title}
            className="h-[7rem] w-[7rem] rounded-2xl object-cover"
            height={480}
            width={480}
          />
        </Link>

        <Link
          href={getProductSlug(product)}
          onClick={() => setCartOpen(false)}
          className="flex w-full justify-between"
        >
          <div className="flex flex-col gap-y-2">
            <h3 className="line-clamp-2  text-[1.8rem] font-medium text-black">
              {product.title}
            </h3>

            <div className="flex items-center gap-x-2">
              <span className="text-elipsis line-clamp-1 text-[1.6rem] font-medium text-brand-dark-gray sm:pr-10">
                {product.category1 === 'Boots' ||
                product.category1 === 'Helmets'
                  ? product.category1
                  : product.category2
                  ? product.category2
                  : product.location_display
                  ? product.location_display
                  : product.category1
                  ? product.category1
                  : product.category}
                {product.size && (
                  <>
                    {product.size?.letter
                      ? `, Size: ${product.size?.letter}`
                      : ''}
                    {product.size?.number
                      ? `${product.size?.letter ? '/ ' : ''} Size: ${
                          product.size?.number
                        }`
                      : ''}
                    {!!product.variations?.length &&
                    product.variations.length > 1
                      ? ', Size: Multiple Sizes'
                      : ''}
                  </>
                )}
              </span>
            </div>
          </div>
        </Link>
      </div>

      <div className="mt-4 flex w-full items-center justify-between">
        <div className="flex items-center gap-x-6">
          <div
            className="flex h-16 w-16 cursor-pointer items-center justify-center rounded-full border border-zinc-300 p-4 text-black"
            onClick={handleDecrease}
          >
            <h3 className={'h-12 w-12'}>
              <MinusIcon className="h-full w-full" />
            </h3>
          </div>

          <h3 className="text-[2rem] font-bold">{cartItem.qty}</h3>

          <div
            className="flex h-16 w-16 cursor-pointer items-center justify-center rounded-full bg-black p-4 text-white"
            onClick={handleIncrease}
          >
            <h3 className={'h-12 w-12'}>
              <PlusIcon className="h-full w-full" />
            </h3>
          </div>
        </div>

        {product.on_sale ? (
          <h3 className="text-[2.2rem] font-bold text-brand-secondary">
            {formatCurrency(product.price)}
          </h3>
        ) : (
          <h3 className="text-[2.2rem] font-bold text-black">
            {formatCurrency(product.og_price)}
          </h3>
        )}
      </div>
    </div>
  );
}

const MxIcon = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.402 10L4 28.8716L8.60234 28.8894L13.8623 10H9.402Z"
        fill="#C41719"
      />
      <path
        d="M21.3437 10.0178L16.1194 15.7574L14.3068 10L12.2988 16.7525L13.827 23.4873L19.4068 16.7703L21.3437 10.0178Z"
        fill="#C41719"
      />
      <path
        d="M20.8993 28.9247L26.2657 10.0353L21.6456 10.0176L16.2969 28.907L20.8993 28.9247Z"
        fill="#C41719"
      />
      <path
        d="M31.1352 28.9423L36.3596 28.9601L34.0139 18.9201L41.3352 10.0707H36.946L33.0188 14.8863L31.9171 10.0529L26.7283 10.0352L28.9318 19.4887L14.8047 36.2635L19.2649 36.2813L29.8914 23.7535L31.1352 28.9423Z"
        fill="#C41719"
      />
      <path
        d="M25.9455 30.0098H25.1636L23.9375 34.3634H26.763L26.9228 33.7237L24.8971 33.6881L25.9455 30.0098Z"
        fill="black"
      />
      <path
        d="M29.4282 33.3503L29.0729 33.6524H28.0777L27.8644 33.3858L28.5219 31.0047L28.8772 30.7382H29.8369L30.0857 31.0047L29.4282 33.3503ZM30.4056 30.0273H28.7529L27.8466 30.6848L27.0293 33.6879L27.5801 34.4165H29.1616L30.1035 33.6702L30.9386 30.7204L30.4056 30.0273Z"
        fill="black"
      />
      <path
        d="M32.8217 32.7639L32.6618 33.4214L32.3242 33.6702H31.4179L31.2047 33.3325L31.8622 31.0758L32.2353 30.7559H33.1593L33.3193 31.058L33.1949 31.5911H33.9768L34.2078 30.6671L33.6924 30.0273H32.1287L31.1513 30.7737L30.334 33.6169L30.9381 34.4165H32.4486L33.3726 33.6879L33.6036 32.7817L32.8217 32.7639Z"
        fill="black"
      />
      <path
        d="M37.8343 30.0449H36.9992L35.009 31.7864L35.4887 30.0449H34.7068L33.4629 34.4163L34.2803 34.4341L34.7246 32.9059L35.4532 32.2662L35.8619 34.4341H36.6438L36.0929 31.6442L37.8343 30.0449Z"
        fill="black"
      />
      <path
        d="M38.2255 32.4439H39.6826L39.8248 31.8397H38.3676L38.652 30.7735H40.7666L40.962 30.0627L38.1366 30.0449L36.875 34.4341H39.7359L39.9314 33.7055H37.8701L38.2255 32.4439Z"
        fill="black"
      />
      <path
        d="M41.4946 31.9457L41.8143 30.8084H42.9516L43.1293 31.0217L42.9872 31.6969L42.6672 31.9813L41.4946 31.9457ZM43.6802 31.9457L44 30.7018L43.5379 30.0977H41.2813L40.0195 34.4868H40.8015L41.2991 32.7098H41.8321L42.1165 34.4868H42.916L42.5963 32.7098H42.8095L43.6802 31.9457Z"
        fill="black"
      />
    </svg>
  );
};
