import { useQuery } from '@tanstack/react-query';
import {
  checkRaEligibility,
  getCartItemsSubtotal,
  getOrderDocFromCartForRA,
} from '@util/firestore/cart';
import { useAuth } from 'context/AuthContext';
import { useShoppingCart } from 'context/ShoppingCartContext';

export const useReturnAssurance = () => {
  const { userDoc } = useAuth();
  const { cart } = useShoppingCart();
  const order = getOrderDocFromCartForRA({
    cart,
    userDoc,
  });
  const { data } = useQuery({
    queryKey: [
      'raEligible',
      order?.product_ids,
      order?.address,
      order?.seller_arr,
    ],
    queryFn: () => checkRaEligibility(order!),
    enabled:
      !!order?.product_ids.length &&
      !!order?.address &&
      !!order?.seller_arr.length,
  });
  if (!cart || !data?.data.is_eligible) return null;
  return (
    (getCartItemsSubtotal(cart.items) + cart.shipping) * (data.data.fee ?? 0.1)
  );
};
