import SafeImage from '@ui/SafeImage';
import Link from 'next/link';
import dollarHandSvg from '../../return-assurance/assets/dollar hand.svg';
import Image from 'next/image';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import Checkbox from '@ui/Checkbox';
import { useReturnAssurance } from '@util/hooks/useReturnAssurance';
import { formatCurrency } from '@util/index';
import { useShoppingCart } from 'context/ShoppingCartContext';

export default function MxReturnOption() {
  const { updateRAFee, cart } = useShoppingCart();

  const raFee = useReturnAssurance();
  if (!cart) return null;
  if (!raFee) return null;

  return (
    <div className="flex w-full flex-col gap-4 rounded-brand bg-brand-secondary/10 p-4">
      <div className="flex gap-4">
        <Checkbox
          label="Make it returnable"
          onChange={() => {
            if (cart.ra_fee) {
              updateRAFee(0);
            } else {
              updateRAFee(raFee);
            }
          }}
          selected={!!cart.ra_fee}
        />
        <Link target="_blank" href="/return-assurance">
          <InformationCircleIcon width={24} height={24} />
        </Link>
      </div>
      <div className="flex gap-4">
        <Image
          src={dollarHandSvg}
          width={40}
          height={40}
          alt="seamless returns and refunds logo"
        />
        <span className="text-[1.4rem] font-normal">
          By adding MX Return Assurance for {formatCurrency(raFee)}, you&apos;ll
          have the option to return your product within 7 days of delivery.
        </span>
      </div>
    </div>
  );
}
