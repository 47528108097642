import { OrderItemDocument } from 'models/order';
import { usePathname } from 'next/navigation';
import React, { createContext, useContext } from 'react';

const DEFAULT_ATTRIBUTION: OrderItemDocument['attribution'] = {
  data_source: 'unknown',
  section: 'unknown',
  page: 'unknown',
};
interface AttributionContext {
  getAttribution: (pid: string) => OrderItemDocument['attribution'];
  setAttribution: (
    pid: string,
    attribution?: OrderItemDocument['attribution']
  ) => void;
}

export const AttributionContext = createContext({} as AttributionContext);

export default function AttributionProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const pathname = usePathname();
  const [attributions, setAttributions] = React.useState<
    Map<string, OrderItemDocument['attribution']>
  >(new Map());

  const getAttribution = (pid: string) => {
    return (
      attributions.get(pid) ?? {
        ...DEFAULT_ATTRIBUTION,
        page: pathname?.split('?')[0],
      }
    );
  };
  const setAttribution = (
    pid: string,
    attribution?: OrderItemDocument['attribution']
  ) => {
    setAttributions(
      new Map(
        attributions.set(
          pid,
          attribution ?? {
            ...DEFAULT_ATTRIBUTION,
            page: pathname?.split('?')[0],
          }
        )
      )
    );
  };

  return (
    <AttributionContext.Provider
      value={{
        getAttribution: getAttribution,
        setAttribution: setAttribution,
      }}
    >
      {children}
    </AttributionContext.Provider>
  );
}

export function useAttributionContext() {
  const context = useContext(AttributionContext);
  if (!context) {
    throw new Error(
      'useNotification must be used within a NotificationProvider'
    );
  }
  return context;
}
