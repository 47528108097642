'use client';
import Image, { ImageProps } from 'next/image';
import { useState } from 'react';
import React from 'react';

interface SafeImageProps extends ImageProps {
  alt: string;
  height?: number;
  width?: number;
  src: any;
  fallbackSrc?: any;
  fallback?: React.ReactNode;
  classes?: string;
  priority?: boolean;
  onFailed?: () => void;
  skipOptimize?: boolean;
  loadingClassName?: string;
}

const RETRYS = 5;

export function transform(url?: string, width = 500, height = width) {
  if (!url) return 'https://mxlocker.com/not_found.svg';
  if (!url.startsWith('http') || url.includes('optimizer=skip')) return url;
  const newUrl = new URL(url);
  newUrl.searchParams.set('width', width.toString());
  newUrl.searchParams.set('height', height.toString());
  newUrl.searchParams.set('t', `${width.toString()}x${height.toString()}`);
  newUrl.searchParams.set('optimizer', 'image');
  newUrl.searchParams.delete('aspect_ratio');
  return newUrl.toString();
}

const SafeImage = ({
  src,
  height = 500,
  width = 500,
  alt,
  fallbackSrc = '/not_found.svg',
  fallback,
  fill = false,
  classes = '',
  priority = false,
  onFailed,
  skipOptimize = false,
  loadingClassName = '',
  ...imageProps
}: SafeImageProps) => {
  const [imgProps, setImgProps] = useState({
    width,
    height,
    src,
  });
  const [retries, setRetries] = useState(0);
  const [error, setError] = useState(false);
  const [retrying, setRetrying] = useState(false);

  const handleOnError = () => {
    setError(true);
    // try to load the image again up to RETRYS times
    if (retries < RETRYS) {
      setRetrying(true);
      setTimeout(() => {
        setRetries(retries + 1);
        setImgProps({
          width,
          height,
          src:
            retries < 2
              ? src
              : retries < 3
              ? imgProps.src.replace('.webp', '.jpg')
              : retries < 4
              ? fallbackSrc
              : '/not_found.svg',
        });
        setRetrying(false);
      }, 1000 * retries);
    } else {
      onFailed?.();
    }
    // else if (
    //   typeof imgProps.src === 'string' &&
    //   imgProps.src.includes('cdn.mxlocker.com') &&
    //   imgProps.src.endsWith('.webp')
    // ) {
    //   // try jpg for cdn images
    //   setImgProps({
    //     width,
    //     height,
    //     src: imgProps.src.replace('.webp', '.jpg'),
    //   });
    // } else if (!imgProps.src.includes('cdn.shopify.com')) {
    //   setImgProps({
    //     width: Math.min(ERROR_MAX_WIDTH, width),
    //     height: Math.min(ERROR_MAX_WIDTH, width),
    //     src: fallbackSrc || '/not_found.svg',
    //   });
    //   setRetrying(false);
    //   setError(false);
    //   onFailed?.();
    // }
  };

  const dimmensionProps = fill
    ? { fill: true }
    : { height: imgProps.height, width: imgProps.width };

  const finalSource =
    typeof imgProps.src === 'string' &&
    !skipOptimize &&
    !imgProps.src.includes('amazonaws') // query params throw off the "signature" required for aws images
      ? transform(imgProps.src, imgProps.width, imgProps.height)
      : imgProps.src;

  if (error && fallback) {
    return <div className={classes}>{fallback}</div>;
  }

  return (
    <>
      <Image
        alt={retrying ? '' : alt}
        key={finalSource + retries}
        src={finalSource || fallbackSrc}
        onError={handleOnError}
        onLoad={() => setError(false)}
        {...dimmensionProps}
        style={{
          ...(retrying && { display: 'none' }),
        }}
        className={classes}
        priority={priority}
        {...imageProps}
        loading={priority ? 'eager' : 'lazy'}
      />

      {(retrying || error) && !fallback && (
        <div
          className={`animate-loading-image z-50 rounded-2xl bg-brand-gray opacity-100 ${loadingClassName}`}
          style={{
            backgroundImage: `linear-gradient(to bottom right, #808080 0%, #c0c0c0 20%, #808080 40%, #808080 100%)`,
            backgroundRepeat: 'no-repeat',
            animation: 'shimmer 2s linear infinite',
            backgroundSize: '1000px 1000px',
          }}
        />
      )}
    </>
  );
};

export default SafeImage;
