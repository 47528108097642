import CheckmarkIconButton from '@c/icons/buttons/CheckmarkButton';

interface RadioProps {
  label: string;
  selected?: boolean;
  disabled?: boolean;
  onChange: (selected?: boolean) => void;
  error?: boolean;
  className?: string;
  checkboxClassName?: string;
}

const Checkbox = ({
  label,
  selected,
  disabled,
  onChange,
  error,
  className,
  checkboxClassName,
}: RadioProps) => {
  return (
    <div
      className={`flex items-center ${
        error ? 'border-4 border-red-500' : ''
      } ${className}`}
    >
      <CheckmarkIconButton
        disabled={disabled}
        checked={selected}
        label={label}
        onClick={() => onChange(!selected)}
        className={checkboxClassName}
      />
    </div>
  );
};

export default Checkbox;
